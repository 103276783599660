import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Archives from "../components/Archives";
import "url-search-params-polyfill";

// eslint-disable-next-line
export const ArchivePageTemplate = ({
  title,
  helmet,
  content,
  contentComponent,
  y2024,
  y2023,
  y2022,
  y2021,
  y2020,
  y2019,
  y2018,
  y2017,
  y2016,
  y2015,
  y2014,
  y2013,
  y2012,
  older,
  query,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content content-border">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <hr />
            <PostContent content={content} />
            <Archives articles={y2024} year={"2024"} query={query} />
            <Archives articles={y2023} year={"2023"} query={query} />
            <Archives articles={y2022} year={"2022"} query={query} />
            <Archives articles={y2021} year={"2021"} query={query} />
            <Archives articles={y2020} year={"2020"} query={query} />
            <Archives articles={y2019} year={"2019"} query={query} />
            <Archives articles={y2018} year={"2018"} query={query} />
            <Archives articles={y2017} year={"2017"} query={query} />
            <Archives articles={y2016} year={"2016"} query={query} />
            <Archives articles={y2015} year={"2015"} query={query} />
            <Archives articles={y2014} year={"2014"} query={query} />
            <Archives articles={y2013} year={"2013"} query={query} />
            <Archives articles={y2012} year={"2012"} query={query} />
            <Archives articles={older} year={"Older"} query={query} />
          </div>
        </div>
      </div>
    </section>
  );
};

const Archive = ({ data, location }) => {
  const { markdownRemark: post } = data;
  const searchQuery =
    new URLSearchParams(location.search).get("keywords") || "";

  return (
    <Layout>
      <ArchivePageTemplate
        helmet={
          <Helmet titleTemplate="%s | Pathlab">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        content={post.html}
        contentComponent={HTMLContent}
        y2024={post.frontmatter.y2024}
        y2023={post.frontmatter.y2023}
        y2022={post.frontmatter.y2022}
        y2021={post.frontmatter.y2021}
        y2020={post.frontmatter.y2020}
        y2019={post.frontmatter.y2019}
        y2018={post.frontmatter.y2018}
        y2017={post.frontmatter.y2017}
        y2016={post.frontmatter.y2016}
        y2015={post.frontmatter.y2015}
        y2014={post.frontmatter.y2014}
        y2013={post.frontmatter.y2013}
        y2012={post.frontmatter.y2012}
        older={post.frontmatter.older}
        query={searchQuery}
      />
    </Layout>
  );
};

Archive.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Archive;

export const pageQuery = graphql`
  query ArchiveByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        y2024 {
          document {
            publicURL
            name
          }
        }
        y2023 {
          document {
            publicURL
            name
          }
        }
        y2022 {
          document {
            publicURL
            name
          }
        }
        y2021 {
          document {
            publicURL
            name
          }
        }
        y2020 {
          document {
            publicURL
            name
          }
        }
        y2019 {
          document {
            publicURL
            name
          }
        }
        y2018 {
          document {
            publicURL
            name
          }
        }
        y2017 {
          document {
            publicURL
            name
          }
        }
        y2016 {
          document {
            publicURL
            name
          }
        }
        y2015 {
          document {
            publicURL
            name
          }
        }
        y2014 {
          document {
            publicURL
            name
          }
        }
        y2013 {
          document {
            publicURL
            name
          }
        }
        y2012 {
          document {
            publicURL
            name
          }
        }
        older {
          document {
            publicURL
            name
          }
        }
      }
    }
  }
`;
