import React from 'react'
import PropTypes from 'prop-types'
import Highlighter from "react-highlight-words"

const Archives = ({ articles, year, query }) => (
  <div style={{ marginTop: `4rem` }}>
    <h4>{year}</h4>
    <hr style={{ margin: `0` }}/>
    <br />
      {articles.map(article => (
        <div key={article.document.name + `article`} className="article-container">
          <div className="article">
            <a href={article.document.publicURL} target="_blank" rel="noopener noreferrer" className="has-text-primary"><strong>
            <Highlighter
              searchWords={query.split(" ")}
              autoEscape={true}
              textToHighlight={articleTrim(article.document.name)}
              highlightStyle={{
                backgroundColor: "#009fb2",
                color: "white"
              }}
            />
            </strong></a>
          </div>
        </div>
      ))}
  </div>
)

function articleTrim(fName) {
  return fName.slice(5);
}

Archives.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    document: PropTypes.object,
  })),
  year: PropTypes.string,
}

export default Archives
